<template>
    <EntitySingle
      :config="config"
      :entity.sync="entity"
      :loaded.sync="entityLoaded"
      :edit-mode="false"
      :submit-callback="submitCallback"
    >
    <!-- <b-card v-if="entityLoaded"> 
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="UsersIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
            </template>
            <EntityEditSection
              :module="config.module"
              :entity.sync="entity"
              :form-fields="formFields"
              allow-autofocus
            />                    
          </b-tab>
        </b-tabs>
    </b-card> -->
    <!-- Display-only fields -->
    <div v-if="entityLoaded" class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Subscription Name') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.userGroup ? entity.userGroup.title : '' }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Subscription Type') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ $t(entity.type) }}</td>
          </tr>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Subscriber Name') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.purchasedUser ? entity.purchasedUser.participant.firstName + " " + entity.purchasedUser.participant.lastName: '' }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Transaction ID') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.paymentId }}</td>
          </tr>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Paid Amount') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.amount }} €</td>
            <th class="font-weight-bold col-md-3">{{ $t('Payment Date') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ formatDateTimeNormal(entity.creationDate) }}</td>
          </tr>
          <tr> 
            <th class="font-weight-bold col-md-3">{{ $t('Start Date') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ formatDateTime(entity.startDate) }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Expiry Date') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ formatDateTime(entity.endDate) }}</td>
          </tr>
          <tr> 
            <th class="font-weight-bold col-md-3">{{ $t('Company Name') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" colspan="3">{{ entity.company.title }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Maximum Products') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.userGroup ? entity.userGroup.maxProducts : '' }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Maximum Partners') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.userGroup ? entity.userGroup.maxPartners : '' }}</td>
          </tr>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Maximum Users') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.userGroup ? entity.userGroup.maxUsers : '' }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Maximum Leads') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.userGroup ? entity.userGroup.maxLeads : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    
      <template v-if="entityLoaded && isAdmin">
        <b-row>
          <b-col cols="12" lg="12">
            <b-tabs v-if="rightsLoaded" pills vertical>
              <b-tab
                v-for="(rightGroup, index) in entity.rights"
                :key="rightGroup.title"
                :title="$t(rightGroup.title)"
                :active="index == 0"
              >
                <user-permissions-card v-if="rightsLoaded" :rights-group="rightGroup" view-mode />
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </template>
    </EntitySingle>
  </template>
  
  <script>
  import axios from '@/libs/axios'
  import { BTab, BTabs, BRow, BCol } from 'bootstrap-vue'
  import { formUtils } from '@core/mixins/ui/forms'
  import EntitySingle from '@/layouts/entity/EntitySingle.vue'
  import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
  import UserViewUserInfoCard from '../../users/users-view/UserViewUserInfoCard.vue'
  import UserPermissionsCard from '../../users/UserPermissionsCard.vue'
  import moment from 'moment-timezone';
  import {
    getUserRights,
    isAdmin,
    isSeller,
    isSupplier,
    canEditPrice,
    canPublishToShop,
    canUnPublishFromShop,
  } from '@/auth/utils'
  import storeModule from '../normalSubscriptionPaymentsStoreModule'
  export default {
    components: {
      BRow,
      BCol,
      EntitySingle,
      EntityEditSection,
      BTab,
      BTabs,
      // Local Components
    UserViewUserInfoCard,
    UserPermissionsCard,
    },
    mixins: [formUtils],
    data() {
      return {
        entity: {
        title: '',
      },
        entityLoaded: false,
        rightsLoaded: false,
        companyName: '',
        packageDetails: {
          id: '',
          title: '',
          maxProducts: '',
          maxUsers: '',
          maxPartners: '',
          maxLeads: '',
          packageRole: '',
        },
        
      }
    },
    computed: {
      rights() {
      return getUserRights()
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
    companyid() {
      return this.entity.companyId;
    },
      config() {
        return {
          store: storeModule,
          module: 'subscriptions',
          endpoint: 'subscriptions',
          route: 'normal-subscription-payments',
          title: {
            single: this.$t('Payment Detail'),
            plural: this.$t('Payment Details'),
          },
        }
      },
    
},
    mounted() {
      this.$watch('entityLoaded', this.fetchUserGroupRights);
      //this.$watch('entityLoaded', this.fetchCompanyDetails);
      if(this.isAdmin){
        this.$watch('entityLoaded', this.fetchPackageDetails);
      }
      this.$watch('entityLoaded', this.formatDateTime);


    },
    methods: {
      formatDateTime(dateTimeString) {
        const athensTime = moment(dateTimeString).tz('Europe/Athens');
        athensTime.locale('el');
        // Format the date using Moment.js format method
        const formattedDate = athensTime.format('D MMM YYYY, HH:mm:ss');
        return formattedDate;
    },
    formatDateTimeNormal(dateTimeString) {
        const athensTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm:ss');
        athensTime.locale('el');
        // Format the date using Moment.js format method
        const formattedDate = athensTime.format('D MMM YYYY, HH:mm:ss');
        return formattedDate;
    },
      fetchUserGroupRights() {
        if (this.entityLoaded) {
          this.$store
            .dispatch('app-common/fetchUserGroupRights', {
              id: this.entity.userGroup.id,
            })
            .then(response => {
              this.entity.rights = this.formatRightsForTable(response.data)
              this.rightsLoaded = true
            })
        }
      },
      // async fetchCompanyDetails() {
      //   try {
      //     //const companyId = this.companyid;
      //     const companyId = this.entity.companyId;
      //     const response = await axios.get(`/companies/${companyId}`);
      //     this.companyName = response.data.title; 
      //   } catch (error) {
      //     console.error('Error fetching company details:', error);
      //   }   
      // },
      async fetchPackageDetails() {
        try {
          const packageId = this.entity.userGroup.id;
          const response = await axios.get(`/user-groups/${packageId}`);
          this.packageDetails = {
            id: response.data.id,
            title: response.data.title,
            maxProducts: response.data.maxProducts,
            maxUsers: response.data.maxUsers,
            maxPartners: response.data.maxPartners,
            maxLeads: response.data.maxLeads,
            packageRole: response.data.role && response.data.role.title ? response.data.role.title : '' ,           
          };
        } catch (error) {
          console.error('Error fetching package details:', error);
        }      
     },
      submitCallback() {
        this.$router.push({ name: 'normal-subscription-payments-list' })
      },
    },
  }
  </script>
  