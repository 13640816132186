<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="entity.avatar"
            :text="avatarText(name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ name }}
              </h4>
              <span class="card-text">{{ entity.participant.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: editPath, params: { id: entity.id } }"
                variant="primary"
              >{{ $t('Edit') }}</b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="handleDeleteClick()"
              >
                {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('company') }}</span>
            </th>
            <td class="pb-50">
              {{ entity.company.title }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('email') }}</span>
            </th>
            <td class="pb-50">
              {{ entity.participant.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Mobile') }}</span>
            </th>
            <td class="pb-50">
              {{ entity.participant.mobile }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UsersIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('User Groups List') }}</span>
            </th>
            <td class="pb-50">
              {{ entity.userGroup.title }}
            </td>
          </tr>
          <!-- <tr>
            <th>
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Last Login') }}</span>
            </th>
            <td>
              {{ entity.lastLoginDate }}
            </td>
          </tr> -->
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    editPath: {
      type: String,
      required: true,
    },
  },
  computed: {
    name() {
      return `${this.entity.participant.firstName} ${this.entity.participant.lastName}`
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    handleDeleteClick() {
      this.$store.dispatch(`${this.endpoint}/deleteEntity`, this.entity.id)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          this.refetchData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'The entity could not be deleted! Check the foreign key constraints first.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style>
</style>
